import React from 'react'
import img from './img/cta-1.png'
import banner from './img/call_to_Action.jpg'


export default function CTA() {
  return (
    <>  
    <section>
    <a target='_blank' className='' href="https://calendly.com/rayan-kaurimmigration" ><img src={banner} alt="" className="img-fluid w-10" /> </a>
    </section>  
    {/* // <section className="bg-primary11">
    //     <div className="container">
    //         <div className="row justify-content-center align-items-center">
    //             <div className="col-md-4">
    //                 <img src={img} alt="" className="img-fluid" />
    //             </div>
    //             <div className="col-md-6 m-center mb-4">
    //                 <h2 className='h1 fw-bold text-white'>Have Any Query?</h2>
    //                 <p className='text-white'>Feel free to get in touch with us.</p>
    //                 <button data-aos="fade-up" className='btn btn-primary px-4'>Book An Appointment! <i className='bi bi-arrow-right-square'></i></button>
    //             </div>
    //         </div>
    //     </div>
    //     </section> */}
    </>

  )
}
