import React from 'react'
import call from './img/bxs_phone-call.png'
import mail from './img/ic_sharp-email.png'
import location from './img/carbon_location-filled.png'
import twitter from './img/twitter.png'
import insta from './img/instagram.png'
import circle1 from './img/circle-1.png'
import circle2 from './img/circle-2.png'

export default function ContactUs() {
  return (
    <section className='contactUS bg-white py-5'>
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2 data-aos="fade-up">Contact Us</h2>
                    <p data-aos="fade-up">Any question or remarks? Just write us a message!</p>
                </div>
            </div>

            <div className="row bg-dark-1 text-white align-items-center">
                <div className="col-md-5 position-relative">
                    <div className="bg-primary-1 my-2 p-5">
                        <h3 className='text-white pt-5'>Contact Information</h3>
                        <p  className='text-white'>Say something to start a live chat!</p>
                        <div className='d-flex py-4'>
                            <img src={call} alt="" className="img-fluid" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a className='text-white text-decoration-none' href='tel:+1012 3456 789'>+1012 3456 789</a>
                        </div>

                        <div className='d-flex py-4'>
                            <img src={mail} alt="" className="img-fluid" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a className='text-white text-decoration-none' href=''>demo@gmail.com</a>
                        </div>

                        <div className='d-flex py-4 mb-5'>
                            <img  src={location} alt="" className="" height='100%' />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a className='text-white text-decoration-none' href=''>132 Dartmouth Street Boston, Massachusetts 02156 United States</a>
                        </div>

                        <ul className='list-unstyled pt-5'>
                            <li>
                                <a href=""><img  src={twitter} alt="" className="img-fluid" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                <a href=""><img  src={insta} alt="" className="img-fluid" /></a>
                            </li>
                        </ul>
                    </div>

                    <img  src={circle1} alt="" className="img-fluid circle1" />
                    <img  src={circle2} alt="" className="img-fluid circle2" />
                   
                </div>
                <div className="col-md-7">
                    <form className='formConnect'>
                        <div className="row">
                            <div className="form-group col-md-6 mt-5">
                                <label  className='fw-bold' htmlFor="first_name">First Name</label>
                                <input  type="text" className="form-control" placeholder='John' />
                            </div>

                            <div className="form-group col-md-6 mt-5">
                                <label  className='fw-bold' htmlFor="last_name">Last Name</label>
                                <input  type="text" className="form-control" placeholder='Doe' />
                            </div>

                            <div className="form-group col-md-6 mt-5">
                                <label  className='fw-bold' htmlFor="email">Email</label>
                                <input  type="mail" className="form-control" placeholder='yourmail@gmail.com' />
                            </div>

                            <div className="form-group col-md-6 mt-5">
                                <label className='fw-bold' htmlFor="phone">Phone Number</label>
                                <input type="text" className="form-control" placeholder='+1 46546 46544' />
                            </div>

                            <div className="form-group col-md-12 mt-5">
                                <label className='fw-bold' htmlFor="subject">Select Subject?</label>
                                <div className="row px-3">

                                    <div className="form-check col-md-3">
                                        <input  className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label  className="form-check-label" for="flexRadioDefault1">
                                            General Enquiry
                                        </label>
                                    </div>

                                    <div className="form-check col-md-3">
                                        <input  className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                        <label  className="form-check-label" for="flexRadioDefault2">
                                            Normal Enquiry
                                        </label>
                                    </div>

                                    <div className="form-check col-md-3">
                                        <input  className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label  className="form-check-label" for="flexRadioDefault1">
                                            General Enquiry
                                        </label>
                                    </div>

                                    <div className="form-check col-md-3">
                                        <input  className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"  />
                                        <label  className="form-check-label" for="flexRadioDefault2">
                                            Normal Enquiry
                                        </label>
                                    </div>
                                    
                                </div>                                
                            </div>

                            <div className="form-group col-md-12 mt-5">
                                <label  className='fw-bold' htmlFor="phone">Message</label>
                                <input  type="text" className="form-control" placeholder='write your message' />
                            </div>

                            <div className="form-group col-md-12 mt-5 text-end">
                                <button  className="btn btn-primary px-5 py-2">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
  )
}
