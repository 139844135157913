import React from 'react'

import canada1 from './img/canada-1.png'
import canada2 from './img/canada-2.png'
import australia1 from './img/australia-1.png'
import australia2 from './img/australia-2.png'
import usa1 from './img/usa-1.png'
import usa2 from './img/usa-2.png'
import newzealand1 from './img/newzealand.png'
import newzealand2 from './img/newzealand-2.png'
import london1 from './img/london-1.png'
import london2 from './img/london-2.png'
import french1 from './img/french-1.png'
import french2 from './img/french-2.png'

export default function StudyAbroad() {
  return (
    <section className='py-5 bg-light mt-5'>
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center mb-4">
                    <h2 data-aos="fade-up">Study Abroad</h2>
                    <p data-aos="fade-up">We have quality partners in variety of destinations around the globe.</p>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4 position-relative mb-5'>
                    <img data-aos="fade-up" src={canada1} alt="" className="img-fluid" />
                    <div className="bannerText2 p-3" >
                        <img data-aos="fade-up" src={canada2} alt="" className="img-fluid mb-3" />
                        <h4 data-aos="fade-up" className='text-white'>Study In Canada</h4>
                        <p data-aos="fade-up" className='text-white'>Canada is prominent for its education programsCanada is prominent for its education programs offering various</p>
                        <a data-aos="fade-up" href="#" className='text-decoration-none primary-text'>Learn More &nbsp;&#8250;</a>
                    </div>
                </div>
                <div className='col-md-4 position-relative mb-5'>
                    <img src={australia1} alt="" className="img-fluid" />
                    <div className="bannerText2 p-3" >
                        <img data-aos="fade-up" src={australia2} alt="" className="img-fluid mb-3" />
                        <h4 data-aos="fade-up" className='text-white'>Study In Australia</h4>
                        <p data-aos="fade-up" className='text-white'>Australia offers a range of courses and the qualification framework to study in Australia is</p>
                        <a data-aos="fade-up" href="#" className='text-decoration-none primary-text'>Learn More &nbsp;&#8250;</a>
                    </div>
                </div>
                <div className='col-md-4 position-relative mb-5'>
                    <img data-aos="fade-up" src={usa1} alt="" className="img-fluid" />
                    <div className="bannerText2 p-3" >
                        <img data-aos="fade-up" src={usa2} alt="" className="img-fluid mb-3" />
                        <h4 data-aos="fade-up" className='text-white'>Study In USA</h4>
                        <p data-aos="fade-up" className='text-white'>The USA has the world’s largest international student population, with more than 1,000,000 students choosing…</p>
                        <a data-aos="fade-up" href="#" className='text-decoration-none primary-text'>Learn More &nbsp;&#8250;</a>
                    </div>
                </div>

                <div className='col-md-4 position-relative mb-5'>
                    <img src={newzealand1} alt="" className="img-fluid" />
                    <div className="bannerText2 p-3" >
                        <img data-aos="fade-up"  src={newzealand2} alt="" className="img-fluid mb-3" />
                        <h4 data-aos="fade-up" className='text-white'>Study In New Zealand</h4>
                        <p data-aos="fade-up" className='text-white'>New Zealand offers diverse range of options and strong international rankings to study in New</p>
                        <a data-aos="fade-up" href="#" className='text-decoration-none primary-text'>Learn More &nbsp;&#8250;</a>
                    </div>
                </div>

                <div className='col-md-4 position-relative mb-5'>
                    <img data-aos="fade-up" src={london1} alt="" className="img-fluid" />
                    <div data-aos="fade-up" className="bannerText2 p-3" >
                        <img data-aos="fade-up" src={london2} alt="" className="img-fluid mb-3" />
                        <h4 data-aos="fade-up" className='text-white'>Study In London</h4>
                        <p data-aos="fade-up" className='text-white'>Australia offers a range of courses and the qualification framework to study in Australia is</p>
                        <a data-aos="fade-up" href="#" className='text-decoration-none primary-text'>Learn More &nbsp;&#8250;</a>
                    </div>
                </div>

                <div className='col-md-4 position-relative mb-5'>
                    <img src={french1} alt="" className="img-fluid" />
                    <div className="bannerText2 p-3" >
                        <img data-aos="fade-up"  src={french2} alt="" className="img-fluid mb-3" />
                        <h4 data-aos="fade-up" className='text-white'>Study In French</h4>
                        <p data-aos="fade-up" className='text-white'>The USA has the world’s largest international student population, with more than 1,000,000 students choosing…</p>
                        <a data-aos="fade-up" href="#" className='text-decoration-none primary-text'>Learn More &nbsp;&#8250;</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
