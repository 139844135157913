import React from 'react'
import logo from './img/logo-1.png'

import footerG1 from './img/footerG1.png'
import footerG2 from './img/footerG2.png'
import footerG3 from './img/footerG3.png'
import footerG4 from './img/footerG4.png'
import footerG5 from './img/footerG5.png'
import footerG6 from './img/footerG6.png'
import email from './img/email.png'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
    <footer className='bg-light border-top bg-dark' >
        <div className="container pt-5">
          <div className="row border-secondary align-items-center border-bottom pb-3">
            <div className="col-md-3">
              <a href='/'>
                <img  src={logo} alt="logoImage" className="img-fluid logo-f" />
              </a>
            </div>
            <div className="col-md-4 mpy-4">
              <h4 className='text-white mb-0 pb-0'>Subscribe Newsletter</h4>
            </div>
            <div className="col-md-5">
              <div className="row subscribe bg-white p-2">
                <div className="col-7  col-md-9">
                  <div className="input-group">
                    <span className="input-group-text" id="email">
                      <img src={email} alt="email" className="img-fluid" />
                    </span>
                    <input type="email" className="form-control border-0 " placeholder="Email" aria-label="email" aria-describedby="email" />
                  </div>
                </div>
                <div className='col-5 col-md-3'>
                  <button className='btn btn-primary border-0'>Subscribe&nbsp;<span className=''>&#8250;</span></button>
                </div>
                
                
              </div>
            </div>
          </div>
          <div className='row py-4'>
            <div className="col-md-5">
              <h5 className='text-white'>About Company</h5>
              <p className='text-secondary-1'>Kaur Immigration is one of the most successful student immigration consultants in Moga. We are connected with most of the top universities in Abroad. We are the global education leaders in the countries like Canada, Germany, USA, Australia and Europe.</p>
            </div>
            <div className="col-md-4">
              <h5 className='text-white'>Quick Links</h5>
              <ul className='text-dec-none ps-0' id='QuickLinks'>
                <li className='pb-2 pt-2'><Link className='text-secondary-1' to="./"><i className="bi bi-chevron-compact-right"></i>&nbsp;Home</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary-1' to="./spouse-visa"><i className="bi bi-chevron-compact-right"></i>&nbsp;Spouse Visa</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary-1' to="./destination"><i className="bi bi-chevron-compact-right"></i>&nbsp;Destination</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary-1' to="./travel-updates"><i className="bi bi-chevron-compact-right"></i>&nbsp;Travel Updates</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary-1' to="./career"><i className="bi bi-chevron-compact-right"></i>&nbsp;career</Link></li>

                <li className='pb-2 pt-2'><Link  className='text-secondary-1' to="./contact"><i className="bi bi-chevron-compact-right"></i>&nbsp;Contact</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary-1' to="./appointment"><i className="bi bi-chevron-compact-right"></i>&nbsp;Appointment</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary-1' to="./terms-of-use"><i className="bi bi-chevron-compact-right"></i>&nbsp;Terms of Use</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary-1' to="./privacy-policy"><i className="bi bi-chevron-compact-right"></i>&nbsp;Privacy Policy</Link></li>
              </ul>
            </div>
            <div className="col-md-3"> 
              
              <div className='row mpx-3'>
                <div className='col-md-12 p-0'>
                  <h5 className='text-white'>Gallery</h5>
                </div>
                <div className="col-md-4 col-4 mb-2 p-0">
                  <img src={footerG1} alt="" className="img-fluid" />
                </div>
                <div className="col-md-4 col-4 mb-2 p-0">
                  <img src={footerG2} alt="" className="img-fluid" />
                </div>
                <div className="col-md-4 col-4 mb-2 p-0">
                  <img src={footerG3} alt="" className="img-fluid" />
                </div>
                <div className="col-md-4 col-4 mb-2 p-0">
                  <img src={footerG4} alt="" className="img-fluid" />
                </div>
                <div className="col-md-4 col-4 mb-2 p-0">
                  <img src={footerG5} alt="" className="img-fluid" />
                </div>
                <div className="col-md-4 col-4 mb-2 p-0">
                  <img src={footerG6} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          
        </div>
    </footer>
    <section className='bg-dark-1 footerLast'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-8">
            <p className='mt-3 text-light m-text-center'>Copyright © 2024 <b className='primary-text'>Kaur Immigration</b> , All Rights Reserved</p>
          </div>
          <div className="col-md-4 text-end">
            <a className='text-secondary-1 text-decoration-none' href=''>Services</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a className='text-secondary-1 text-decoration-none' href=''>FAQ's</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a className='text-secondary-1 text-decoration-none' href=''>Career</a>
          </div>
          
        </div>
      </div>
    </section>
    </>
  )
}
