import React, { useState } from "react";
import uk from './img/uk-f.png'
import germany from './img/germany-f.png'
import usa from './img/usa-f.png'
import canada from './img/canada-f.png'
import ireland from './img/ireland-f.png'
import swit from './img/switzerland-f.png'
import italy from './img/italy-f.png'
import france from './img/france-f.png'
import spain from './img/spain-f.png'

import ut from './img/ut.png'
import gmu from './img/gmu.png'



const PartnerC = () => {
    const [activeTab, setActiveTab] = useState("home");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            <section className="pb-5 partnerSpouseVisa">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 text-center mb-4">
                      <h2 className="fw-bold">Our Top Partner <span className="text-primary1 h1 fw-bold">Universities</span></h2>
                      
                    </div>
                  </div>
                  <div className="row justify-content-center text-center">
                    <div className="col-md-10 justify-content-center">
                      <ul className="nav nav-tabs">
                          <li className="nav-item">
                              <a className={`nav-link ${activeTab === "home" ? "active" : ""}`} data-bs-toggle="tab" href="#home" onClick={() => handleTabClick("home")}>
                                <img src={usa} alt="usa" className="img-fluid icon" />  USA
                              </a>
                          </li>
                          <li className="nav-item">
                              <a className={`nav-link ${activeTab === "menu1" ? "active" : ""}`} data-bs-toggle="tab" href="#menu1" onClick={() => handleTabClick("menu1")}>
                              <img src={uk} alt="usa" className="img-fluid icon" />  UK
                              </a>
                          </li>
                          <li className="nav-item">
                              <a className={`nav-link ${activeTab === "menu2" ? "active" : ""}`} data-bs-toggle="tab" href="#menu2" onClick={() => handleTabClick("menu2")}>
                              <img src={canada} alt="usa" className="img-fluid icon" />  CANADA
                              </a>
                          </li>
                          <li className="nav-item">
                              <a className={`nav-link ${activeTab === "menu3" ? "active" : ""}`} data-bs-toggle="tab" href="#menu3" onClick={() => handleTabClick("menu3")}>
                              <img src={italy} alt="usa" className="img-fluid icon" />  <img src={france} alt="usa" className="img-fluid icon" /> ITALY & FRANCE
                              </a>
                          </li>
                      </ul>

                      <div className="tab-content pt-4">
                          <div className={`tab-pane fade ${activeTab === "home" ? "show active" : ""}`} id="home">                              
                              <div className="row align-items-center justify-content-center">
                                <div className="col-md-2 col-6 mb-2">
                                    <div className="uniname">
                                        <img src={ut} alt="uni_name" className="img-fluid UniName" />
                                    </div>
                                    
                                </div>
                                <div className="col-md-2 col-6 mb-2">
                                    <div className="uniname">
                                        <img src={gmu} alt="uni_name" className="img-fluid UniName" />
                                    </div>
                                </div>
                              </div>
                          </div>
                          <div className={`tab-pane fade ${activeTab === "menu1" ? "show active" : ""}`} id="menu1">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-2 col-6 mb-2">
                                    <div className="uniname">
                                        <img src={ut} alt="uni_name" className="img-fluid UniName" />
                                    </div>
                                    
                                </div>
                                <div className="col-md-2 col-6 mb-2">
                                    <div className="uniname">
                                        <img src={gmu} alt="uni_name" className="img-fluid UniName" />
                                    </div>
                                </div>
                              </div>
                          </div>
                          <div className={`tab-pane fade ${activeTab === "menu2" ? "show active" : ""}`} id="menu2">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-2 col-6 mb-2">
                                    <div className="uniname">
                                        <img src={ut} alt="uni_name" className="img-fluid UniName" />
                                    </div>
                                    
                                </div>
                                <div className="col-md-2 col-6 mb-2">
                                    <div className="uniname">
                                        <img src={gmu} alt="uni_name" className="img-fluid UniName" />
                                    </div>
                                </div>
                              </div>
                          </div>
                          <div className={`tab-pane fade ${activeTab === "menu3" ? "show active" : ""}`} id="menu3">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-2 col-6 mb-2">
                                    <div className="uniname">
                                        <img src={ut} alt="uni_name" className="img-fluid UniName" />
                                    </div>
                                    
                                </div>
                                <div className="col-md-2 col-6 mb-2">
                                    <div className="uniname">
                                        <img src={gmu} alt="uni_name" className="img-fluid UniName" />
                                    </div>
                                </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                    
                    

                    
                </div>
            </section>
        </>
    );
};

export default PartnerC;
