import React from 'react'


export default function Stats() {
  return (
    <div class="position-relative">
    <section className="contact-layout3 bg-overlay bg-overlay-primary-gradient">
      <div className="container pt-5 pb-4">
        <div className="row justify-content-center">
            <div className="col-md-2 counter" >
              <div className="green">
                <div className="counter-icon">
                    <span className="icon-like">👍</span>
                </div>
                <div className="counter-number" data-value="10000" data-append="+">10000+</div>
                <div className="counter-title">Clients</div>
              </div>
            </div>
            <div className="col-md-2 counter">
              <div className="yellow">
                <div className="counter-icon">
                    <span className="icon-pulse">💓</span>
                </div>
                <div className="counter-number" data-value="95" data-append="%">95%</div>
                <div className="counter-title">Visa Success Rate</div>
              </div>
                
            </div>
            <div className="col-md-2 counter">
              <div className="red">
                <div className="counter-icon">
                    <span className="icon-star">⭐</span>
                </div>
                <div className="counter-number" data-value="150" data-append="+">150+</div>
                <div className="counter-title">Edu Partners</div>
              </div>
                
            </div>
            <div className="col-md-2 counter">
              <div className="orange">
                <div className="counter-icon">
                    <span className="icon-medal">🏅</span>
                </div>
                <div className="counter-number" data-value="10500" data-append="+">10500+</div>
                <div className="counter-title">Visa Approvals </div>
              </div>
                
            </div>
            <div className="col-md-2 counter">
              <div className="green">
                <div className="counter-icon">
                    <span className="icon-like">👍</span>
                </div>
                <div className="counter-number" data-value="1000" data-append="+">1000+</div>
                <div className="counter-title">Spouse Visa Approvals</div>
              </div>
                
            </div>
            <div className="col-md-2 counter">
              <div className="yellow">
                <div className="counter-icon">
                    <span className="icon-pulse">💓</span>
                </div>
                <div className="counter-number" data-value="5" data-append=" Years">5 Years</div>
                <div className="counter-title">Career Counselling Support</div>
              </div>
                
            </div>
        </div>
      </div>
    </section>
    </div>
  )
}
