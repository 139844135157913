import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import canada from './img/canada.png';
import america from './img/amrica.png';
import london from './img/london.png';
import spain from './img/spain.png';
import french from './img/french.png';
import sweden from './img/sweden.png';
import italy from './img/italy.png';
import Marquee from "react-fast-marquee"


const App = () => {


  return (
    <>
      <section className='bg-light py-4 mt-5 topdes'>
        <div className='container'>
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 data-aos="fade-up">Top Destinations</h2>
              <p data-aos="fade-up">We have quality partners in variety of destinations around the globe.</p>
            </div>
          </div>
          <Marquee  pauseOnHover='false'>
              <div className='d-flex flex-wrap gap-4'>
                  <div className="slider-item position-relative">
                     <div className="slider-img " >
                       <img data-aos="fade-up" src={canada} alt="" className="img-fluid sliderImg"/>
                     </div>
                  </div>

                  <div className="slider-item position-relative">
                     <div className="slider-img">
                       <img data-aos="fade-up" src={america} alt="" className="img-fluid sliderImg" />
                     </div>
                  </div>

                 <div className="slider-item position-relative">
                    <div className="slider-img">
                       <img data-aos="fade-up" src={london} alt="" className="img-fluid sliderImg" />
                    </div>
                 </div>

                 <div className="slider-item position-relative">
                    <div className="slider-img">
                      <img data-aos="fade-up" src={spain} alt="" className="img-fluid sliderImg" />
                    </div>
                 </div>

                 <div className="slider-item position-relative">
                    <div className="slider-img">
                      <img data-aos="fade-up" src={french} alt="" className="img-fluid sliderImg" />
                    </div>
                 </div>

                 <div className="slider-item position-relative">
                    <div className="slider-img">
                      <img data-aos="fade-up" src={sweden} alt="" className="img-fluid sliderImg" />
                    </div>
                </div>

                 <div className="slider-item position-relative">
                    <div className="slider-img">
                       <img data-aos="fade-up" src={italy} alt="" className="img-fluid sliderImg" />
                    </div>
                </div>
              </div>
          </Marquee>
          <div className="row position-relative mb-4 align-items-center text-center mx-auto justify-content-center mt-5">
            <div className="col-md-4">
                <button data-aos="fade-up" className="btn btn-primary apply-O px-5 py-3">Apply Online</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default App;
