import React, { useState, useRef } from 'react';

import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactTest = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    // Your other form fields
    recaptchaValue: null, // Initialize the reCAPTCHA value
  });

  const [error, setError] = useState(null);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Check if the reCAPTCHA value is provided
    if (!formData.recaptchaValue) {
      setError('Please complete the reCAPTCHA verification.');
      return;
    }

    // Reset the error state
    setError(null);

    // Perform your form submission logic here
    emailjs.sendForm('service_3nibnhn', 'template_2sy1vnc', form.current, 'YIIaEB3NYc5K8grKq')
      .then((result) => {            
          alert("Message Sent Successfully");
      }, (error) => {
          alert("Error! Please try again.");
      });
    //console.log('Form submitted with reCAPTCHA:', formData);
  };

  const handleRecaptchaChange = (value) => {
    // Update the form state with the reCAPTCHA value
    setFormData({
      ...formData,
      recaptchaValue: value,
    });

    // Clear the error when the user completes the reCAPTCHA
    setError(null);
  };

  return (
    <div className="container">

      <div className="row justify-content-center align-items-center">
        <div className="col-md-12 text-center">
          <small>HAVE SOME QUESTIONS?</small>
          <h2>Let's Chat</h2>

          <p>Feel free to get in touch with us.</p>
        </div>
        <div className="col-md-6" id='Cont_form'>
          <form className='row' ref={form} onSubmit={handleFormSubmit}>
            {/* Your other form fields */}

              <div className="col-md-6 form-group mb-4">                                    
                  <input type="text" name='first_name' placeholder='First Name *' className="form-control" required />
              </div>

              <div className="col-md-6 form-group mb-4">                                    
                  <input type="text" name='last_name' placeholder='Last Name *' className="form-control" required />
              </div>

              <div className="col-md-6 form-group mb-4">                                    
                  <input type="text" name='Mobile' placeholder='Mobile / Phone No *' className="form-control" required />
              </div>

              <div className="col-md-6 form-group mb-4">                                    
                  <input type="email" name='email' placeholder='Email Adress*' className="form-control" required />
              </div>

              <div className="col-md-12 form-group mb-4">                                    
                  <input type="text" name='subject' placeholder='Subject*' className="form-control" required />
              </div>

              <div className="col-md-12 form-group mb-4">                                    
                  <textarea className='form-control' rows={5} name='message' placeholder='Message*'></textarea>
              </div>
            
            {/* Add the reCAPTCHA component */}
            <ReCAPTCHA
              sitekey="6Lcl0ygoAAAAAHKrsouKfVZMhLnkt6qZe2ecLy9x"
              onChange={handleRecaptchaChange}
              theme="light"
            />

            {/* Display the error message if reCAPTCHA is not completed */}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div className="col-md-12 form-group mt-3">
                <button type='submit' value="Send" className="btn btn-success px-5 mx-auto text-center">Submit</button>
            </div>            
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactTest;
