import React from 'react'
import flyDream from './img/fly-img.png'
import flyYour from './img/fly-your.png'

export default function FlyDreamDestination() {
  return (
    <section className='fly-dream-destination py-5'>
        <div className="container">
            <div className="row">
                <div className="col-md-4 p-0">
                    <img data-aos="fade-up" src={flyDream} alt="" className="img-fluid" />
                </div>
                <div className="col-md-8 p-0 flydream-bg py-4 px-5 position-relative">
                    <h2 data-aos="fade-up" className='h1 text-white'>Fly Your Dream Destination</h2>
                    <p data-aos="fade-up" className='text-white'>Idea of denouncing pleasure & praising pain was born.</p>
                    <ul className='list-unstyled py-4'>
                        <li data-aos="fade-up" className='text-white mb-2'><span className='primary-text'>&#10003;&nbsp;</span>Entering & Leaving From Country</li>
                        <li data-aos="fade-up" className='text-white mb-2'><span className='primary-text'>&#10003;&nbsp;</span>Visas</li>
                        <li data-aos="fade-up" className='text-white'><span className='primary-text'>&#10003;&nbsp;</span>Country Citizenship</li>
                    </ul>
                    <button data-aos="fade-up" className="btn btn-primary border-0 py-3 px-4">CONTACT US</button>
                    <img data-aos="fade-up" src={flyYour} alt="img" className='fly-ii' />
                </div>
            </div>
        </div>
    </section>
  )
}
