import React from 'react'
import sstory1 from './img/s-story-1.png'
import sstory2 from './img/s-story-2.png'
import sstory3 from './img/s-story-3.png'
import sstory4 from './img/s-story-4.png'
import sstory5 from './img/s-story-5.png'
import sstory6 from './img/s-story-6.png'
import sstory7 from './img/s-story-7.png'

export default function SuccessStory() {
  return (
    <section className="success-story bg-dark py-5">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <p data-aos="fade-up" className='primary-text pb-0 mb-0'>Kaur Immigration</p>
                    <h2 data-aos="fade-up" className='text-white text-uppercase fw-normal'>Success Stories</h2>
                </div>
            </div>
            <div className="row pt-3">
                <div className="col-md-5 p-0">
                    <img data-aos="fade-up" src={sstory1} alt="" className="img-fluid" />
                </div>
                <div className="col-md-7">
                    <div className="row p-0 immg">
                        <div className="col-md-4 p-0">
                            <img data-aos="fade-up" src={sstory2} alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-4 p-0">
                            <img data-aos="fade-up" src={sstory3} alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-4 p-0">
                            <img data-aos="fade-up" src={sstory4} alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-4 p-0">
                            <img data-aos="fade-up" src={sstory5} alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-4 p-0">
                            <img data-aos="fade-up" src={sstory6} alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-4 p-0">
                            <img data-aos="fade-up" src={sstory7} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
