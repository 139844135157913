import {React, useState,useEffect} from 'react';
import{Link} from 'react-router-dom';
import logo from './img/logo-1.png';
export default function Navbar() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleDropdownClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleNavLinkClick = () => {
        setIsDropdownOpen(false);
    };

  return (
   <>
   
   <section className='bg-primary11'>
    <div className="container topBar">
        <div className='row py-2'>
            <div className='col-md-4'>
                <p className='mb-0 pb-0 text-white'><i class="bi bi-geo-alt-fill text-white"></i>&nbsp;&nbsp;Sunrise Glass Studio, GT Road Duneke, Moga</p>
            </div>
            

            <div className='col-md-5 ms-auto float-end text-end'>
                <a className='text-white text-decoration-none'><i class="bi bi-facebook"></i></a>&nbsp;&nbsp;<a className='text-white text-decoration-none'><i class="bi bi-twitter"></i></a>&nbsp;&nbsp;<a className='text-white text-decoration-none'><i class="bi bi-linkedin"></i></a>&nbsp;&nbsp;<a className='text-white text-decoration-none'><i class="bi bi-instagram"></i></a>
            </div>
        </div>
    </div>
   </section>
   <nav className="navbar navbar-expand-lg navbar-light bg-light mb-70">
        <div className="container">
            <div className='row'>
                <div className='col-md-2'>
                    <a className="navbar-brand" href="/">
                        <img src={logo} className='img-fluid logo' alt="logo" />
                    </a>
                </div>
                <div className='col-md-10'>
                <button className="navbar-toggler" type="button"  data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded={isDropdownOpen ? 'true' : 'false'} aria-label="Toggle navigation" onClick={handleDropdownClick}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                    <div className={`collapse navbar-collapse ${isDropdownOpen ? 'show' : ''}`} id="navbarScroll">
                        <ul className="navbar-nav ms-auto float-end text-end py-3 my-lg-0 navbar-nav-scroll" id='mobileMenu'>
                            <li className="nav-item">
                                <span className='nav-link' onClick={handleNavLinkClick}>
                                <Link to="/">Home</Link>
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className='nav-link' onClick={handleNavLinkClick}>
                                <Link to="/spouse-visa">Spouse Visa</Link>
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className='nav-link' onClick={handleNavLinkClick}>
                                <Link to="/destination">Destinations</Link>
                                </span>
                            </li>
                            
                            <li className="nav-item">
                                <span className='nav-link' onClick={handleNavLinkClick}>
                                <Link to="/travel-updates">Travel Updates</Link>
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className='nav-link' onClick={handleNavLinkClick}>
                                <Link to="/career">Career</Link>
                                </span>
                            </li>
                            {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/"  id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded={isDropdownOpen ? 'true' : 'false'}>
                                    Channel Partner
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown" onClick={handleDropdownClick}>
                                    <li><span className="dropdown-item" onClick={handleNavLinkClick}><Link to="k-news-telugu">K-News Telugu</Link></span></li>
                                    <li><span className="dropdown-item" onClick={handleNavLinkClick}><Link to="kapotham-telugu-news">Kapotham Telugu News</Link></span></li>
                                    <li><span className="dropdown-item" onClick={handleNavLinkClick}><Link to="mr-hyderabadi">Mr. Hyderbadi</Link></span></li>
                                    <li><span className="dropdown-item" onClick={handleNavLinkClick}><Link to="bhavyas-media">Bhavy's Media</Link></span></li>
                                </ul>
                            </li> */}
                            <li className="nav-item">
                                <span className='nav-link' onClick={handleNavLinkClick}>
                                <Link to="/contact">Contact</Link>
                                </span>
                            </li>

                            <li className="nav-item">
                                <span className='nav-link' onClick={handleNavLinkClick}>
                                    <a target='_blank' className='but' href="https://calendly.com/rayan-kaurimmigration" >&#10509; &nbsp;Appointment</a>
                                </span>
                            </li>

                            
                        </ul>                        
                    </div>
                </div>
            </div>
        </div>
      </nav>
      
    
  </>
  )
}



// Navbar.defaultProps={
//     title: 'Logo/Brand',
//     activePageName:'Active Page Name'
// }