import React from 'react'
import about1 from './img/about-11.png'
import bg2 from './img/about-22.png'
import bg3 from './img/about-33.png'
import about2 from './img/about-44.png'
import about3 from './img/about-55.png'
import plane from './img/plane.png'

export default function About() {
  return (
    <section className='aboutt'>
      <div className="container py-5">
        <div className="row align-items-center justify-content-center position-relative mob-cr">
            <img src={plane} alt="" className="img-fluid plane" />
            <div className="col-md-6">
              <div className="row">
                <div className="col-6 position-relative">
                  <img data-aos="fade-up" src={about1} alt="" className="img-fluid about-img" />
                  <img data-aos="fade-up" src={bg2} alt="img" className="img-fluid bg-img" />
                  <img data-aos="fade-up" src={bg3} alt="img" className="img-fluid bg-shade" />
                </div>
                <div className='col-6 ps-5'>
                  <div className="row">
                    <div className="col-11">
                      <img data-aos="fade-up" src={about2} alt="img" className="img-fluid p-3" />
                    </div>
                    <div className="col-11">
                      <img data-aos="fade-up" src={about3} alt="img" className="img-fluid p-3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
                <button data-aos="fade-up" className='btn btn-primary px-3 mb-3'>
                About us
                </button>
                <h2 data-aos="fade-up" className='mb-3'>Kaur Immigration</h2>
                <p data-aos="fade-up">                    
                At Kaur Immigration provides information regarding higher education in various countries such as Canada, Australia, USA, New-zealand and Europe has a Professional command over the education system worldwide. We are well known in the immigration circle for our specialized services & are praised by our clients for enriching their experience in Visa facilitation. 
                </p>
                <p data-aos="fade-up">We have precision knowledge in the fields of Immigration, Visa processing and other related services. </p>
                
                <a data-aos="fade-up" href=""><button className="btn btn-primary rounded-pill px-4">Learn More&nbsp;&#8250;</button></a>
            </div>
            
        </div>
      </div>
    </section>
  )
}
