import React from 'react'
import banner from './img/career-updates-main-banner.jpg'
import img1 from './img/denmark.jpg'
import img2 from './img/denmark1.jpg'
import img3 from './img/denmark3.jpg'
import img4 from './img/denmark4.jpg'

export default function Denmark() {
  return (
    <>
    <section className=''>
        <img src={banner} alt="" className="img-fluid w-100"  />        
    </section>
    <section className='py-5'>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-12">
                    <h2>Denmark – <span className='text-primary1'>New Favorite Destination</span> for Indian Students To Study Abroad</h2>
                    
                    
                    
                </div>
                <div className="col-md-4">
                <p>Are you contemplating pursuing higher education abroad in Denmark? Denmark, with its captivating blend of rich history, stunning landscapes, and a world-renowned education system, emerges as an ideal destination for students seeking a unique and enriching academic experience. In this blog, we’ll delve into the compelling reasons why studying in Denmark could be your gateway to a transformative educational journey.</p>
                
                </div>
                <div className="col-md-8">
                    <img src={img1} alt="img" className='img-fluid rounded' />
                </div>
                <div className="col-md-12 pt-3">
                <p>Denmark is the new favorite of Indian students when it comes to studying abroad. This new favoritism and likability are not just Denmark’s top-notch educational policies but also its livability and mesmerizing landscapes.</p>
                <p>Denmark is one of the happiest nations in the international world and has one of the highest GDPs. It has the lowest crime rate in the world, and the work week is much shorter than in different nations. The best thing about Denmark is that a low or no tuition fee is required to study. These things are essential if you are moving to a new country.</p>
                    <p>Denmark has emerged as a prominent and enticing destination for Indian students seeking to pursue higher education abroad. Known for its cutting-edge academic institutions, welcoming environment, and emphasis on innovation, Denmark offers a unique blend of educational excellence and cultural richness that has captured the attention of Indian students.</p>
                    <p>A safe and sound environment is necessary for growth and stability, especially for students, since moving to a new country can be stressful. Your learning environment can influence your learning ability, career choices, and overall health. Denmark checks all the points that an Indian student wants in their environment when studying abroad; hence, it is the top choice and new favorite of students who wish To study in Denmark.</p>
                </div>
                <div className="col-md-8 pt-3">
                    <h3 className='text-primary1'>Reasons to study in Denmark-</h3>
                    <p><b>High-quality education:</b> Denmark has a strong reputation for providing high-quality education. Its universities and educational institutions consistently rank among the top globally.</p>
                    <p><b>Innovative teaching methods:</b> Danish universities emphasize interactive learning and encourage students to actively engage in discussions, projects, and problem-solving. This approach fosters critical thinking and collaboration skills.</p>
                    <p><b>Tuition Fees and Scholarship:</b> Studying in Denmark is not as expensive as you think. Tuition fees in Denmark vary on the level and duration of the education. The cheapest degree in Denmark starts from 6000 EUR per year which is comparatively affordable than other European countries. Some universities or colleges also offer scholarships to students on a merit basis as they are looking for crème students who can also contribute to the standard of their education level. International Students can also apply for the Erasmus Mundus Scholarship Programme under which all the expenses are funded by European Union for both EU and non-EU students. This scholarship is available for the master's or doctoral level. Students with good English proficiency and excellent academic background can apply for these scholarships.   </p>
                    <p className='h5'>Erasmus offers full-time scholarships including monthly allowance, participation costs, traveling, and insurance costs for the students.</p>
                    <p><b>English-taught programs:</b> Many degrees in Denmark are taught in English, making it an attractive destination for international students who may not be fluent in Danish.</p>
                </div>

                <div className="col-md-4 pt-3">
                    <img src={img2} alt="img" className='img-fluid rounded' />
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-md-6">
                    <img src={img3} alt="img" className='img-fluid rounded' />
                </div>
                <div className="col-md-6">
                    
                    <p><b>Research opportunities:</b> Denmark is known for its research-intensive environment, offering numerous opportunities for students to engage in cutting-edge research projects with renowned scholars.</p>
                    <p><b>Strong focus on sustainability and green technology:</b> Denmark is at the forefront of sustainable development and green technology initiatives. Students interested in fields like renewable energy, environmental studies, or sustainable design will find ample resources and expertise available.</p>
                    <p><b>Supportive student welfare system:</b> The Danish government provides various support systems for international students regarding housing assistance, healthcare access, part-time jobs during studies, and other benefits that contribute to a positive student experience.</p>
                    <p><b>Multicultural environment:</b> Studying in Denmark offers exposure to a diverse multicultural atmosphere with students from around the world coming together to learn, share experiences, and build lasting connections.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <h3>Work opportunities and <span className='text-primary1'>staying back option after graduation:</span></h3>
                    <p>International students will have to get residence permits to work in the country after graduation. The residence permit stays valid for six months after graduation, helping them find employment in Denmark. There are temporary residence and work permits available along with the Establishment Card (completing a bachelor’s/master’s/PhD degree), which allows students to remain in Denmark and find employment. Employers can also apply for work and residence permits on behalf of their employees, while there is also the Fast-Track scheme for foreign employee recruitment. Also, you can start your businesses through the Danish Post-Graduation Work Permit Scheme.</p>
                    <p>Spouse and kids visa –In Denmark, spouse and kid are allowed on study visas. Also, the spouse would have full-time work rights which is a fair deal. Students can easily concentrate on their studies while spouse can manage the expenses.</p>
                </div>
            </div>

            <div className="row align-items-center pt-4">
                <div className="col-md-6">
                    <h3><span className='fw-bold text-primary1'>Right Cities to Live and Study</span> -Some of the best cities for students include: </h3>
                    <p><span className='text-primary1 fw-bold'>Aalborg-</span> Aalborg is a cultural and research hub and home to the famous Aalborg University and many other institutions. The city is also known for its museums, annual Aalborg Carnival, and vibrant nightlife. </p>
                    <p><span className='text-primary1 fw-bold'>Aarhus-</span> Aarhus University is the key educational landmark here, along with several other educational institutions. The city is popular for its green zones, parks, open-air museums, and great food.</p>
                    <p><span className='text-primary1 fw-bold'>Copenhagen-</span> Denmark’s capital is home to the University of Copenhagen and many other leading institutions while offering a plethora of historical and cultural landmarks and great nightlife. </p>
                    <p><span className='text-primary1 fw-bold'>Odense-</span> Odense is a sparsely-populated city and a major country commercial and shopping center. The University of Southern Denmark is located here. It is the birthplace of the iconic Hans Christian Andersen and is home to several museums, galleries, and a zoo. </p>
                </div>
                <div className="col-md-6">
                    <img src={img4} alt="img" className='img-fluid rounded' />
                </div>
                <div className='col-md-12'>
                    <h4><b>Cost of Living:</b> Here’s summing up the approximate <span className='fw-bold text-primary1'>cost of living in the country:</span> </h4>
                    <p><b>Accommodation- 400-670</b> Euros in most Danish cities and around 800-900 Euros for Copenhagen. </p>
                    <p><b>Food- 200-270 Euros each month</b>, while dining out usually costs around 30 Euros for each individual. </p>
                    <p><b>Transportation- 40-50 Euros per month</b> is the approximate cost of a monthly transportation pass. </p>

                    <h2 className='fw-bold text-primary1'>Best Courses To Study In Denmark</h2>
                    <p>It does not matter in which city or university in Denmark you are studying because each university offers a wide range of courses to enlighten your academic path. With natural science, social science, engineering, web development, environmental management, hospitality, business administration, modern languages, design, communication, international studies, sales and marketing courses, Denmark offers something to all students with different interests.</p>
                    <p className='h5'>Some of the best courses that international students opt to study in Denmark are:</p>
                    <table className='table table-bordered responsive-table'>
                        <th>
                            <td>Course/Program</td>
                            <td>Duration</td>
                        </th>
                        <tr>
                            <td>Bachelor of Architectural Technology and Construction Management</td>
                            <td>3 - 5  Years</td>
                        </tr>
                        <tr>
                            <td>M.Sc. Agricultural Development</td>
                            <td>2 Years</td>
                        </tr>

                        <tr>
                            <td>MBA</td>
                            <td>1 - 2 Years</td>
                        </tr>

                        <tr>
                            <td>M.Sc. Economics</td>
                            <td>2 Years</td>
                        </tr>

                        <tr>
                            <td>M.Sc. Environmental Science</td>
                            <td>2 Years</td>
                        </tr>


                        <tr>
                            <td>M.Sc. Design and Innovation</td>
                            <td>2 Years</td>
                        </tr>

                        <tr>
                            <td>M.Sc. Civil Engineering</td>
                            <td>2 Years</td>
                        </tr>

                        
                    </table>
                </div>

            </div>
        </div>
    </section>
    </>
  )
}
