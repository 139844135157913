import React from 'react'
import post1 from './img/recent-post-1.png'
import post2 from './img/recent-post-2.png'
import post3 from './img/recent-post-3.png'

export default function RecentPost() {
  return (
    <section className="recent-post bg-light py-5">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <h2 data-aos="fade-up" className='text-center fw-bold'>Recent Posts</h2>
                    <p data-aos="fade-up" className='text-center'>With lots of unique blocks, you can easily build a page without coding. Build your next landing page.</p>
                </div>
            </div>

            <div className="row py-5">
              <div className="col-md-4">
                <div className="recent-post-box">
                  <img data-aos="fade-up" src={post1} alt="" className="img-fluid" />
                  <div className="content-b bg-white p-4">
                    <h5>Career</h5>
                    <p className='mb-0'>How to win any job you want. Get started with 5 steps.</p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="recent-post-box">
                  <img data-aos="fade-up" src={post2} alt="" className="img-fluid" style={{borderRadius:"10px"}} />
                  <div className="content-b bg-white p-4">
                    <h5>Lifestyle</h5>
                    <p className='mb-0'>10 ways to reduce your office work depression.</p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="recent-post-box">
                  <img data-aos="fade-up" src={post3} alt="" className="img-fluid" />
                  <div className="content-b bg-white p-4">
                    <h5>Career</h5>
                    <p className='mb-0'>Why should you work as a team even on small projects.</p>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </section>
  )
}
