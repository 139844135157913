import React from 'react'
import cVisa from './img/c-visa.png'
import pVisa from './img/p-visa.png'
import tVisa from './img/T-Visa.png'
import gVisa from './img/g-visa.png'
import arrow from './img/arrow.png'

export default function HowWeWork() {
  return (
    <section className="how-we-work bg-dark py-5">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-5 text-center">
                    <button  data-aos="fade-up" className="btn btn-primary px-3 py-1 mb-3">How We Work</button>
                    <h2 data-aos="fade-up" className="fw-bold text-white">How To Get Your Visa</h2>
                    <p data-aos="fade-up" className='text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod enim
                    tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div className="row pt-5 position-relative">
                <div className="col-md-3 px-5 col-6">
                    <img data-aos="fade-up" src={cVisa} alt="" className="img-fluid" />
                    <h6 data-aos="fade-up" className='primary-text mt-3'>Choose Your Visa</h6>
                    <p data-aos="fade-up" className='text-white'>Lorem ipsum dolor sit amet,
                    consecte adipiscing elit.</p>
                </div>
                <img data-aos="fade-up" src={arrow} alt="img" className="arrowH1" />

                <div className="col-md-3 px-5 mt-5 pt-5 col-6">
                    <img data-aos="fade-up" src={tVisa} alt="" className="img-fluid" />
                    <h6 data-aos="fade-up" className='primary-text mt-3'>Take Training</h6>
                    <p data-aos="fade-up" className='text-white'>Lorem ipsum dolor sit amet,
                    consecte adipiscing elit.</p>
                </div>

                <div className="col-md-3 px-5 col-6">
                    <img data-aos="fade-up" src={pVisa} alt="" className="img-fluid" />
                    <h6 data-aos="fade-up" className='primary-text mt-3'>Pay Visa Fee</h6>
                    <p data-aos="fade-up" className='text-white'>Lorem ipsum dolor sit amet,
                    consecte adipiscing elit.</p>
                </div>
                <img data-aos="fade-up" src={arrow} alt="img" className="arrowH2" />

                <div  className="col-md-3 px-5 mt-5 pt-5 col-6">
                    <img data-aos="fade-up" src={gVisa} alt="" className="img-fluid" />
                    <h6 data-aos="fade-up" className='primary-text mt-3'>Get Your Visa</h6>
                    <p  data-aos="fade-up" className='text-white'>Lorem ipsum dolor sit amet,
                    consecte adipiscing elit.</p>
                </div>


            </div>
        </div>
    </section>
  )
}
