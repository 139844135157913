import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import slider1 from './img/service1-1.png'
import slider1icon from './img/service1-1-bg.png'

import slider2 from './img/service1-2.png'
import slider2icon from './img/service1-2-bg.png'

import slider3 from './img/service1-3.png'
import slider3icon from './img/service1-3-bg.png'

const ResponsiveAutoSlider = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState(4);

  const handleResize = () => {
    if (window.innerWidth >= 1200) {
      setVisibleItems(3);
    } else if (window.innerWidth >= 992) {
      setVisibleItems(3);
    } else if (window.innerWidth >= 768) {
      setVisibleItems(2);
    } else {
      setVisibleItems(1);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % Math.ceil(items.length / visibleItems));
    }, 3000);

    return () => clearInterval(interval);
  }, [items.length, visibleItems]);

  const handleSelect = (selectedIndex) => {
    setCurrentIndex(selectedIndex);
  };

  const getItemsForSlide = (slideIndex) => {
    let slideItems = [];
    for (let i = 0; i < visibleItems; i++) {
      const index = (slideIndex * visibleItems + i) % items.length;
      slideItems.push(items[index]);
    }
    return slideItems;
  };

  const totalSlides = Math.ceil(items.length / visibleItems);

  return (
    <Carousel
      activeIndex={currentIndex}
      onSelect={handleSelect}
      indicators={true}
      controls={true}
      interval={null}
    >
      {Array.from({ length: totalSlides }).map((_, idx) => (
        <Carousel.Item key={idx}>
          <div className="d-flex justify-content-center">
            {getItemsForSlide(idx).map((item, subIdx) => (
              <div className="p-2" key={subIdx}>
                {item}
              </div>
            ))}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};


const App = () => {
  const items = [
    <div className="slider-item position-relative">
      <div  className='service'>
        <img data-aos="fade-up" src={slider1} alt="" className='serviceImg' />
      </div>
      <img src={slider1icon} alt="" className="img-fluid slider-icon" />
      <div className="slider-text">        
        <h3 data-aos="fade-up" className='text-center fw-bold'>Student Visa</h3>
        <p data-aos="fade-up" className='text-center'>Sed ut perspiciatis unde omnis iste natus</p>
      </div>
    </div>,

    <div className="slider-item position-relative">
      <div  className='service'>
        <img data-aos="fade-up" src={slider2} alt="" className='serviceImg' />
      </div>
      <img data-aos="fade-up" src={slider2icon} alt="" className="img-fluid slider-icon" />
      <div className="slider-text">        
        <h3 data-aos="fade-up" className='text-center fw-bold'>Business Visa</h3>
        <p data-aos="fade-up" className='text-center'>Sed ut perspiciatis unde omnis iste natus</p>
      </div>
    </div>,

    <div className="slider-item position-relative">
      <div  className='service'>
        <img data-aos="fade-up" src={slider3} alt="" className='serviceImg'/>
      </div>
      <img  data-aos="fade-up"  src={slider3icon} alt="" className="img-fluid slider-icon" />
      <div className="slider-text">        
        <h3  data-aos="fade-up" className='text-center fw-bold'>Work Visa</h3>
        <p  data-aos="fade-up" className='text-center'>Sed ut perspiciatis unde omnis iste natus</p>
      </div>
    </div>,

    <div className="slider-item position-relative" >
      <div className='service'>
        <img data-aos="fade-up" src={slider1} alt=""  className='serviceImg'/>
      </div>
      <img data-aos="fade-up" src={slider1icon} alt="" className=" slider-icon" />
      <div className="slider-text">        
        <h3 data-aos="fade-up" className='text-center fw-bold'>Student Visa</h3>
        <p data-aos="fade-up" className='text-center'>Sed ut perspiciatis unde omnis iste natus</p>
      </div>
    </div>,
    <div className="slider-item position-relative">
      <div className='service'>
        <img data-aos="fade-up" src={slider2} alt="" className='serviceImg'/>
      </div>
      <img data-aos="fade-up" src={slider2icon} alt="" className="img-fluid slider-icon" />
      <div className="slider-text">        
        <h3 data-aos="fade-up" className='text-center fw-bold'>Business Visa</h3>
        <p data-aos="fade-up" className='text-center'>Sed ut perspiciatis unde omnis iste natus</p>
      </div>
    </div>,
    <div className="slider-item position-relative">
      <div  className='service'>
        <img data-aos="fade-up" src={slider3} alt="" className="serviceImg rounded"/>
      </div>
      <img data-aos="fade-up" src={slider3icon} alt="" className="img-fluid slider-icon" />
      <div className="slider-text">        
        <h3 data-aos="fade-up" className='text-center fw-bold'>Work Visa</h3>
        <p data-aos="fade-up" className='text-center'>Sed ut perspiciatis unde omnis iste natus</p>
      </div>
    </div>,
  ];

  return (
    <>
    <section className='services pb-5 pt-5 mb-5 bg-light'>
      <div className='container'>
        <div className="row">     
          <div className="App">
            <h6 data-aos="fade-up" className='text-center'>Service We Provide</h6>
            <h2 data-aos="fade-up" className='text-center fw-bold pb-4'>Explore Our Visa Citizenship<br></br>
            & <span className='primary-text'>Immigration Services</span></h2>
            <ResponsiveAutoSlider items={items} />
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default App;
