import React from 'react'
import sliderImg from './img/bg-1.png'
import sliderImg2 from './img/img-2.png'
import sliderImg3 from './img/slider-3.webp'


export default function SliderSec() {
  return (
    <section>
        <div className="container  ">
            <div className="row align-items-center">
                <div className="col-md-6">
                    <div className="bannerText" data-aos="fade-up">
                        <h1  data-aos="fade-up">Education in the Way to <span className='primary-text'>Success</span></h1>
                        <div className="row justify-content-center">
                            <div className="col-md-4 col-4 counter">
                                <div className="green">
                                
                                    <div  data-aos="fade-up" className="counter-number border-bottom border-primary-1 border-2" data-value="10000" data-append="+" id='clientscount'>10000+</div>
                                    <div  data-aos="fade-up" className="counter-title">Clients</div>
                                </div>                                
                            </div>
                            <div className="col-md-4 col-4 counter" >
                                <div className="yellow">                                
                                    <div  data-aos="fade-up" className="counter-number border-bottom border-primary-1 border-2" data-value="95" data-append="%" >95%</div>
                                    <div  data-aos="fade-up" className="counter-title">Visa Success Rate</div>
                                </div>                                
                            </div>
                            
                            <div className="col-md-4 col-4 counter">
                                <div className="orange">                                
                                    <div   data-aos="fade-up" className="counter-number border-bottom border-primary-1 border-2" data-value="10500" data-append="+">10500+</div>
                                    <div  data-aos="fade-up" className="counter-title">Visa Approvals </div>
                                </div>                                
                            </div>                            
                        </div>
                        {/* <p className='h5 fw-normal mt-3'>Eduplan Education Can Fulfil Your International Education Dream
                        As Per Your Best Fit with world top universities and collages.</p>
                        <button className="btn btn-primary rounded-pill px-3 mt-4">Learn More &nbsp;&#8250;</button> */}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className='img mt-1' data-aos="fade-up">
                        <img src={sliderImg2} alt="img" className="img-fluid " />
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
