import React from 'react'
import vertigo from './img/vertigo.png'
import minty from './img/minty.png'
import glossy from './img/glossy.png'
import wave from './img/wave.png'
import rise from './img/rise.png'
import Marquee from "react-fast-marquee"

export default function PartnersAf() {
  return (
    <section className='partner mt-2'>
        <div className="container">
            <div className="row">
                <div className="col-md-3 partnerS">
                    <h2 className='text-white py-4 ps-4 fw-bold' data-aos="fade-up">
                    PARTNERS <span className='text-dark'>AFFILIATION</span>
                    </h2>
                </div>
                <div className="col-md-9">
                  <Marquee pauseOnHover='false'>
                      <div className="row align-items-center py-5">
                        <div className="col-auto">
                            <img src={vertigo} alt="" className="img-fluid px-3" />
                        </div>

                        <div className="col-auto">
                            <img src={minty} alt="" className="img-fluid px-3" />
                        </div>

                        <div className="col-auto">
                            <img src={glossy} alt="" className="img-fluid px-3" />
                        </div>

                        <div className="col-auto">
                            <img src={wave} alt="" className="img-fluid px-3" />
                        </div>

                        <div className="col-auto">
                            <img src={rise} alt="" className="img-fluid px-3" />
                        </div>
                      </div>
                  </Marquee>
                </div>
            </div>
        </div>
    </section>
  )
}
