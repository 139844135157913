// Testimonial.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import testimonial1 from './img/testimonial-1.png';
import icon from './img/5-star-icon.png';
import client1 from './img/testi1-thumb1.png';
import list from './img/List.png';
import quote from './img/Icon.png';

const Testimonial = () => {
  return (
    <section className="testimonial position-relative mb-5">
        <div className="container-fluid mb-5">
            <div className="row align-items-center mb-5">
                <div className="col-md-4 p-0">
                    <img data-aos="fade-up" src={testimonial1} alt="img" className="img-fluid testimonial-img1" />
                    <img data-aos="fade-up" src={quote} alt="img" className="img-fluid quote" />
                    <div className='rating-count'>
                        <h3>8K+</h3>
                        <p className='mb-0'>People Say About Us</p>
                    </div>
                </div>
                <div className="col-md-8 bg-dark mob-Z p-0 py-5">
                <div id="demo" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
                    </div>
                    <div className="carousel-inner">
                    <div className="carousel-item active">
                        
                        <div className='SliderText'>    
                            <img data-aos="fade-up" src={icon} alt="slider" className=' img-fluid mb-3' />              
                            <p data-aos="fade-up" className='text-white'>At vero eos et accusamus et iusto odio dignissimos ducimus blanditiis praesentium voluptatum delenitie atque corrupti quos dolores quas molestias excepte sint occaecati cupiditate provident similique </p>
                            <div className="row pt-4">
                                <div className='col-md-8 d-flex'>
                                    <img data-aos="fade-up" src={client1} alt="img" className="img-fluid" />&nbsp;&nbsp;&nbsp;
                                    <div>
                                        <h5 data-aos="fade-up">Arthur A. Vandusen</h5>
                                        <p data-aos="fade-up">CEO & Founder</p>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <h6 data-aos="fade-up">Quality Services</h6>
                                    <img data-aos="fade-up" src={list} alt="img" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">                
                        <div className='SliderText'>    
                                <img data-aos="fade-up" src={icon} alt="slider" className=' img-fluid mb-3' />              
                                <p data-aos="fade-up" className='text-white'>At vero eos et accusamus et iusto odio dignissimos ducimus blanditiis praesentium voluptatum delenitie atque corrupti quos dolores quas molestias excepte sint occaecati cupiditate provident similique </p>
                                <div className="row pt-4">
                                    <div className='col-md-8 d-flex'>
                                        <img data-aos="fade-up" src={client1} alt="img" className="img-fluid" />&nbsp;&nbsp;&nbsp;
                                        <div>
                                            <h5 data-aos="fade-up">Arthur A. Vandusen</h5>
                                            <p data-aos="fade-up">CEO & Founder</p>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <h6 data-aos="fade-up">Quality Services</h6>
                                        <img data-aos="fade-up" src={list} alt="img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="carousel-item">
                        <div className='SliderText'>    
                                <img data-aos="fade-up" src={icon} alt="slider" className=' img-fluid mb-3' />              
                                <p  data-aos="fade-up" className='text-white'>At vero eos et accusamus et iusto odio dignissimos ducimus blanditiis praesentium voluptatum delenitie atque corrupti quos dolores quas molestias excepte sint occaecati cupiditate provident similique </p>
                                <div className="row pt-4">
                                    <div className='col-md-8 d-flex'>
                                        <img data-aos="fade-up" src={client1} alt="img" className="img-fluid" />&nbsp;&nbsp;&nbsp;
                                        <div>
                                            <h5 data-aos="fade-up">Arthur A. Vandusen</h5>
                                            <p data-aos="fade-up">CEO & Founder</p>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <h6 data-aos="fade-up">Quality Services</h6>
                                        <img data-aos="fade-up" src={list} alt="img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                    </div>
                    </div>
                    {/* <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon"></span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                    <span className="carousel-control-next-icon"></span>
                    </button> */}
                </div>
                </div>
            </div>
        </div>
      
    </section>
  );
};

export default Testimonial;
