import React, { useEffect } from 'react'
import PartnerC from '../PartnerC'
import uk from '../img/uk-f.png'
import germany from '../img/germany-f.png'
import usa from '../img/usa-f.png'
import canada from '../img/canada-f.png'
import ireland from '../img/ireland-f.png'
import swit from '../img/switzerland-f.png'
import italy from '../img/italy-f.png'
import france from '../img/france-f.png'
import spain from '../img/spain-f.png'
import banner from '../img/destination-updates-main-banner.jpg'
import CTA from '../CTA'
import RecentPost from '../RecentPost'
import Marquee from 'react-fast-marquee'

export default function DestinationIndex() {

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToTop()   
    },[])
    
  return (
    <>

    <section className=''>
      <img src={banner} alt="" className="img-fluid w-100"  />        
    </section>

    {/* <section className='py-5 contactBG'>
        <div className="container">
            <div className="row align-items-center justify-content-center text-center">
                <div className="col-md-7 py-5">
                    <h1 data-aos="fade-up"><span className="text-white fw-bold">Grow quickly, Manage easily- Make your students happy with Kaur Immigration</span></h1>                
                    <p data-aos="fade-up text-white"><span className="text-white">Experience a fast, transparent, and smooth application process along with the best commissions. With Kaur Immigration, you can just focus on your students and leave the rest to us. With our 20+ years of experience and a large network of 900+ Universities, your success is guaranteed!</span> </p>
                    <h6 data-aos="fade-up text-white"><span className="text-white">For more info</span></h6>
                    <button data-aos="fade-up" className='btn btn-primary mb-5'>Contact Us! <i className='bi bi-arrow-right-square'></i></button>
                </div>
            </div>
        </div>
    </section> */}
    <section className='py-5'>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 mb-5">
                    <h2 className='text-primary1 fw-bold text-center'>Destination Country</h2>
                </div>
             <Marquee className='d-flex gap-4'>
               <div className='d-flex flex-wrap gap-4'>
                <div className="">
                    <div className="countryinfo">
                        <img src={uk} alt="" className="img-fluid"/>
                        <h5 className=' pt-2 text-center'>UK</h5>
                    </div>                    
                </div>

                <div className="">
                    <div className="countryinfo">
                        <img src={germany} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>Germany</h5>
                    </div>                    
                </div>

                <div className="">
                    <div className="countryinfo">
                        <img src={usa} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>USA</h5>
                    </div>                    
                </div>

                <div className="">
                    <div className="countryinfo">
                        <img src={canada} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>CANADA</h5>
                    </div>                    
                </div>


                <div className="">
                    <div className="countryinfo">
                        <img src={ireland} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>IRELAND</h5>
                    </div>                    
                </div>

                <div className="">
                    <div className="countryinfo">
                        <img src={swit} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>SWITZERLAND</h5>
                    </div>                    
                </div>

                <div className="">
                    <div className="countryinfo">
                        <img src={italy} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>ITALY</h5>
                    </div>                    
                </div>

                <div className="">
                    <div className="countryinfo">
                        <img src={france} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>FRANCE</h5>
                    </div>                    
                </div>

                <div className="">
                    <div className="countryinfo">
                        <img src={spain} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>SPAIN</h5>
                    </div>                    
                </div>
              </div>
             </Marquee>
            </div>
        </div>
    </section>
    <PartnerC></PartnerC>
    <CTA></CTA>
    <RecentPost></RecentPost>
    </>
    
  )
}
