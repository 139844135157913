import React, { useState } from 'react';
import ContactForm from './ContactForm'
import CTA from './CTA'
import callIcon from './img/icons8-headset.gif'
import mapIcon from './img/icons8-map-marker.gif'
import email from './img/icons8-email.gif'
import banner from './img/contact-updates-main-banner.jpg'

export default function Contact() {
  const [activeTab, setActiveTab] = useState('home');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
    
    <section className=''>
      <img src={banner} alt="" className="img-fluid w-100"  />        
    </section>
    {/* <section className='py-5 contactBG'>
    <img src={banner} alt="" className="img-fluid" />
        <div className="container">
            <div className="row align-items-center justify-content-center text-center">
                <div className="col-md-7 py-5">
                    <h1 data-aos="fade-up"><span className="text-white">Contact</span></h1>                
                    <p data-aos="fade-up text-white"><span className="text-white">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</span> </p>
                    <h6 data-aos="fade-up text-white"><span className="text-white">For more info</span></h6>
                    <button data-aos="fade-up" className='btn btn-primary'>Contact Us! <i className='bi bi-arrow-right-square'></i></button>
                </div>
            </div>
        </div>
    </section> */}

    <section className='py-5'>
      <div className="container">
        <div className="row">

          <div className="col-md-3 position-relative">
              
              <div className="address text-center shadow">
                <h5 className='h6'><img src={callIcon} alt="" className="img-fluid" /></h5>
                
                <h6 className='mt-5 text-primary1 fw-bold pb-2'>Office Phone Number</h6>
                <p><a href='tel: +91-96926-00084' className='text-dark text-decoration-none'>+91-96926-00084</a></p>
                <p><a href='tel: +91-96927-00084' className='text-dark text-decoration-none'>+91-96927-00084</a></p>
                <p><a href='tel: +91-96928-00084' className='text-dark text-decoration-none'>+91-96928-00084</a></p>
                
              </div>
            
          </div>

          <div className="col-md-6 position-relative">
            <div className="address text-center shadow">
              <h5><img src={mapIcon} alt="" className="img-fluid" /> Office</h5>
              <ul className="nav nav-tabs mt-5">
                <li className="nav-item">
                  <a 
                    className={`nav-link ${activeTab === 'home' ? 'active' : ''}`} 
                    data-bs-toggle="tab" 
                    href="#home" 
                    onClick={() => handleTabClick('home')}
                  >
                    <i class="bi bi-geo-alt"></i>&nbsp;Moga
                  </a>
                </li>
                <li className="nav-item">
                  <a 
                    className={`nav-link ${activeTab === 'menu1' ? 'active' : ''}`} 
                    data-bs-toggle="tab" 
                    href="#menu1" 
                    onClick={() => handleTabClick('menu1')}
                  >
                    <i class="bi bi-geo-alt"></i>&nbsp;Amritsar
                  </a>
                </li>
                <li className="nav-item">
                  <a 
                    className={`nav-link ${activeTab === 'menu2' ? 'active' : ''}`} 
                    data-bs-toggle="tab" 
                    href="#menu2" 
                    onClick={() => handleTabClick('menu2')}
                  >
                    <i class="bi bi-geo-alt"></i>&nbsp;Hyderabad
                  </a>
                </li>
                <li className="nav-item">
                  <a 
                    className={`nav-link ${activeTab === 'menu3' ? 'active' : ''}`} 
                    data-bs-toggle="tab" 
                    href="#menu3" 
                    onClick={() => handleTabClick('menu3')}
                  >
                    <i class="bi bi-geo-alt"></i>&nbsp;Zira
                  </a>
                </li>
              </ul>
              <div className="tab-content pt-4 p-4 pb-0 mb-0">
                <div className={`tab-pane fade ${activeTab === 'home' ? 'show active' : ''}`} id="home">
                  <p>Kaur Immigration & Education Services , Ferozepur road, Near Sri Satya Sai Murlidhar Ayurvedic College, opp. Sunrise Glass Studio, GT Road Duneke, Moga</p>
                </div>
                <div className={`tab-pane fade ${activeTab === 'menu1' ? 'show active' : ''}`} id="menu1">
                  <p>SCO 41, Veer Enclave, Near Golden Gate and Ryan International School, Bypass Road, Amritsar</p>
                </div>
                <div className={`tab-pane fade ${activeTab === 'menu2' ? 'show active' : ''}`} id="menu2">
                  <p>Office No.301, 3rd Floor, “Sonathalia Emerald”, Raj Bhavan Road, Somajiguda, Hyderabad</p>
                </div>
                <div className={`tab-pane fade ${activeTab === 'menu3' ? 'show active' : ''}`} id="menu3">
                  <p>Above UCO Bank, Ferozpur Road, Zira</p>
                </div>
              </div>
            </div>
          </div>

          
          <div className="col-md-3 position-relative">
           
              <div className="address email text-center shadow">
                <h5 className='h6 '><img src={email} alt="" className="img-fluid" /></h5>
                
                <h6 className='mt-5 text-primary1 fw-bold pb-2'>Mail for information</h6>
                <p><a href='mailto: info@kaurimmigration.com' className='text-dark text-decoration-none'>info@kaurimmigration.com</a></p>
                <p><a href='mailto: kaurimmigration14@gmail.com' className='text-dark text-decoration-none'>kaurimmigration14@gmail.com</a></p>
                <p><a href='mailto: kaurimmigration20@gmail.com' className='text-dark text-decoration-none'>kaurimmigration20@gmail.com</a></p>
                <p><a href='mailto: kaurimmigration21@gmail.com' className='text-dark text-decoration-none'>kaurimmigration21@gmail.com</a></p>
                
                
              </div>
            
          </div>
        </div>
      </div>
    </section>

    <ContactForm></ContactForm>
    <CTA></CTA>
    </>
  )
}
